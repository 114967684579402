import * as React from "react";
import { Heading, Text, Image, Flex, Box } from "rebass";
import Page from "../components/Page";
import IndexLayout from "../layouts";
import { Helmet } from "react-helmet";
import * as styles from "./Story.module.css";
import kevinProfile from "../assets/images/kevin.png";
import storyIcon1 from "../assets/images/story-icon-1.png";
import storyIcon2 from "../assets/images/story-icon-2.png";
import storyIcon3 from "../assets/images/story-icon-3.png";
import SiteFooter from "../components/SiteFooter";

import HeroLayoutEmpty from "../components/HeroLayoutEmpty";

import HeroGridCard from "../components/HeroGridCard";
import HeroGrid from "../components/HeroGrid";

const KevinStoryPage = () => {
  return (
    <IndexLayout>
      <Page>
        <HeroGrid
          style={{ position: "relative", overflow: "hidden", rowGap: "0" }}
        >
          <Helmet>
            <title>Skodel - Kevin's Story</title>
            <meta property="og:title" content="Skodel - Kevin's Story" />
            <meta
              property="og:description"
              content="Read Kevin's story, how Skodel supports wellbeing at Scots College and why he is passionate about this area"
            />
          </Helmet>
          <HeroGridCard
            className={styles.storyKevinContainer}
            color="white"
            style={{ maxWidth: "unset" }}
          >
            <Box className={styles.heroStoryKevinStripes}>
              <Box className={styles.heroStoryKevinStripesInner}>
                <Box className={styles.heroStoryKevinStripe1} />
                <Box className={styles.heroStoryKevinStripe2} />
                <Box className={styles.heroStoryKevinStripe3} />
              </Box>
            </Box>
            <HeroLayoutEmpty className={styles.storyKevinWrapper}>
              <Flex justifyContent="center">
                <Box className={styles.storyKevinContent}>
                  <Box className={styles.storyKevinImage}>
                    <Image src={kevinProfile} alt="A photo of Kevin Brennan" />
                  </Box>
                  <Heading className={styles.storyKevinName}>
                    Kevin Brennan
                  </Heading>
                  <Heading className={styles.storyKevinOccupation}>
                    Assistant Head of Senior School
                  </Heading>
                  <Heading className={styles.storyKevinOccupationBig}>
                    Key Takeaway from Skodel: Identifying unknown wellbeing
                    issues
                  </Heading>
                  <Text className={styles.storyKevinMessage}>
                    Kevin Brennan is responsible for looking after the Scots
                    College Boarding Community. In our time working with Kevin,
                    we can think of no one better for the job. It is well known
                    that good teaching starts with strong relational connection
                    and Kevin is someone who can quickly strike a connection
                    with his students. His capacity to build strong
                    relationships is founded on an eagerness to get to know his
                    students. He wants to know who they are, what they want to
                    be, their goals, their vulnerabilities and more so he can
                    have more meaningful conversations with them. In fact, when
                    we first met Kevin to discuss Skodel Check-In, Kevin was
                    already actively searching for a tool to manage the check-in
                    process with his students to help him achieve exactly that.
                    We asked Kevin three questions about his teaching journey,
                    his answers offered insight into where his passion for
                    teaching comes from and highlighted just how special it is
                    to be a teacher.
                  </Text>
                  <Box className={styles.storySparator}>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                    <Box className={styles.storySparatorBullet}></Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon1} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        What inspired you to become a teacher?
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        I really enjoyed school as a student and always remember
                        looking at my teachers and thinking "that would be a
                        really cool job". Pretty much from the age of 15 I
                        decided that was what I would do when I left school.
                      </Text>
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon2} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        Describe one moment that made you realise how special it
                        is to be a teacher.
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        A number of years ago I had a young man in my boarding
                        house that found things very difficult socially. He was
                        a really nice, kind person, but was incredibly shy and
                        therefore really struggledto fit in and be himself. His
                        challenges followed him throughout his high school
                        years, and whilst I always tried to support him, I never
                        felt like I was ever having an impact. When he graduated
                        school in Year 12 he wrote me a long letter, it was 3 or
                        4 pages in length. In it he thanked me for the support
                        and help I had given him over the 5 years he had been in
                        my boarding house, giving examples from every year of
                        things and incidents where he had remembered things I
                        had specifically done and the difference it had made to
                        him. I had no idea at the time, but what he wrote made
                        me realise what a special privilege we have to work with
                        young people during such an important time in their
                        lives. It was probably the single nicest gesture a
                        student had done for me to make me realise how special
                        it is to be a teacher.
                      </Text>
                    </Box>
                  </Box>
                  <Box className={styles.storyGroup}>
                    <Box className={styles.storyGroupImage}>
                      <Image src={storyIcon3} alt="" />
                    </Box>
                    <Box className={styles.storyGroupText}>
                      <Heading className={styles.storyGroupTitle}>
                        Do you have a Skodel story for us? A moment where Skodel
                        has helped you make a difference in a student/s life.
                      </Heading>
                      <Text className={styles.storyGroupDescription}>
                        We have been using Skodel Check-In with our Boarding
                        Students. It has been a great tool for us grabbing quick
                        snapshots of how boys are travelling, giving us quick up
                        to date information that allows us to begin
                        conversations of support where necessary. A recent
                        example was one of our senior boys, a College Prefect:
                        he is a very talented and highly respected student, hard
                        working in the classroom, part of the College Band and a
                        1st team representative. We thought he was thriving in
                        every possible way at school. On one occasion he
                        received the question, "Is there anything you are
                        worried about at the moment?" He responded with a
                        concern he had been having. None of the staff were aware
                        that he had been carrying this worry around with him for
                        months, and the information was just what we needed in
                        order to provide him with the support he needed.
                      </Text>
                      <Text className={styles.storyGroupDescription}>
                        When we reflect on our own personal educational journey,
                        very rarely do we look back and thank our teachers for
                        teaching us the subject matter. What we thank them for
                        is believing in us, inspiring us, supporting us, taking
                        the time to get to know us and showing a genuine
                        interest in our lives. We often remember moments or
                        conversations that, to the outside world, would appear
                        to be insignificant, but they remain in our minds
                        forever. This impact may only become apparent when we
                        have time to sit back, reflect on our schooling journey
                        and give thanks to the teachers who were there for us.
                        For teachers like Kevin Brennan, a thank you is one of
                        the biggest rewards he can receive.
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </HeroLayoutEmpty>
          </HeroGridCard>
          <SiteFooter />
        </HeroGrid>
      </Page>
    </IndexLayout>
  );
};

export default KevinStoryPage;
